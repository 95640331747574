import React, { FunctionComponent, useState } from "react";
import {
    Badge,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Link,
    Box,
    Text,
    VStack,
} from "@chakra-ui/react";
import { PiShare } from "react-icons/pi";
import { Link as ReachLink } from "@reach/router";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { getColor, hexToRGBA } from "../../app";

const NUM_STRATEGIES_SUMMARY = 3;

export type SelectedClassData = {
    Class: string;
    Grade: string;
};

type Props = {
    selectedClassData: SelectedClassData;
    strategies: {
        id: number;
        label: string;
        description: string;
        colorScheme: string;
    }[];
};

export const TopStrategies: FunctionComponent<Props> = ({
    selectedClassData,
    strategies,
}) => {
    const [itemsToShow, setItemsToShow] = useState(NUM_STRATEGIES_SUMMARY);

    const showMoreItems = () => {
        setItemsToShow(strategies.length);
    };
    const showLessItems = () => {
        setItemsToShow(NUM_STRATEGIES_SUMMARY);
    };

    return (
        <>
            <HStack justifyContent={"space-between"} w={"100%"}>
                <Heading size={"md"} color={"#032E59"}>
                    Top Strategies for Your Class {selectedClassData.Class}{" "}
                    (Grade: {selectedClassData.Grade})
                </Heading>
                {/* <Button
                    ml={"20px"}
                    color={"white"}
                    borderRadius={"60px"}
                    h={"24px"}
                    bg={"#136AFF"}
                    leftIcon={<PiShare />}
                    fontSize={"12px"}
                    onClick={() => {
                        //TODO add action
                    }}
                >
                    Share with Colleague
                </Button> */}
            </HStack>
            <VStack spacing={"17px"}>
                {((!strategies || !strategies.length) && (
                    <Box color="#FF0000" pt="30px">
                        A student questionnaire has NOT been completed.
                    </Box>
                )) || (
                    <Grid
                        templateColumns="repeat(3, 1fr)"
                        gap={6}
                        w={"100%"}
                        bg={"#E8F2FF"}
                        borderRadius={"20px"}
                        padding={"23px 14px"}
                    >
                        {strategies
                            .slice(0, itemsToShow)
                            .map((strategy, index) => {
                                const color = getColor(strategy.colorScheme);
                                const rgbaColor = hexToRGBA(color, 0.2);
                                return (
                                    <GridItem
                                        key={index}
                                        w="100"
                                        h="auto"
                                        bg={"#FFFFFF"}
                                        borderRadius={"16px"}
                                        border={"1px solid #E1ECFF"}
                                        padding={"15px 20px"}
                                        fontSize={"12px"}
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                        gap={"5px"}
                                    >
                                        <Text>{strategy.description}</Text>
                                        <HStack
                                            justifyContent={"space-between"}
                                            w={"100%"}
                                        >
                                            <HStack>
                                                <Badge
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    minWidth={"20px"}
                                                    minHeight={"20px"}
                                                    borderRadius={"40px"}
                                                    variant="solid"
                                                    background={"#E8F2FF"}
                                                    color={"#032E59"}
                                                    fontSize={"8px"}
                                                >
                                                    {strategy.level}
                                                </Badge>
                                                <Badge
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    minWidth={"20px"}
                                                    minHeight={"20px"}
                                                    borderRadius={"40px"}
                                                    variant="solid"
                                                    background={rgbaColor}
                                                    p={"4px 7px"}
                                                    fontSize={"8px"}
                                                    color={"#032E59"}
                                                >
                                                    {
                                                        strategy.cognitiveAreaLabel
                                                    }
                                                </Badge>
                                            </HStack>
                                            {/* <HStack>
                                            <Link
                                                color={"#032E59"}
                                                to={"/"}
                                                as={ReachLink}
                                                fontSize={"10px"}
                                                fontWeight={500}
                                                lineHeight={"20px"}
                                            >
                                                Resources
                                            </Link>
                                            <Link
                                                color={"#032E59"}
                                                to={"/"}
                                                as={ReachLink}
                                                fontSize={"10px"}
                                                fontWeight={500}
                                                lineHeight={"20px"}
                                            >
                                                Feedback
                                            </Link>
                                        </HStack> */}
                                        </HStack>
                                    </GridItem>
                                );
                            })}
                        <GridItem
                            colSpan={3}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            position={"relative"}
                        >
                            {itemsToShow < strategies.length ? (
                                <Button
                                    borderRadius={"50px"}
                                    borderColor={"#ACCBFF"}
                                    variant="outline"
                                    color={"#032E59"}
                                    fontSize={"12px"}
                                    p={"6px 13px"}
                                    lineHeight={"13px"}
                                    maxH={"25px"}
                                    onClick={showMoreItems}
                                    rightIcon={
                                        <BiChevronDown
                                            size={20}
                                            fill={"#FF4DD1"}
                                        />
                                    }
                                >
                                    View More
                                </Button>
                            ) : (
                                <Button
                                    borderRadius={"50px"}
                                    borderColor={"#ACCBFF"}
                                    variant="outline"
                                    color={"#032E59"}
                                    fontSize={"12px"}
                                    p={"6px 13px"}
                                    lineHeight={"13px"}
                                    maxH={"25px"}
                                    onClick={showLessItems}
                                    rightIcon={
                                        <BiChevronUp
                                            size={20}
                                            fill={"#FF4DD1"}
                                        />
                                    }
                                >
                                    View Less
                                </Button>
                            )}
                            <Button
                                as={ReachLink}
                                to={`strategies`}
                                borderRadius={"50px"}
                                borderColor={"#ACCBFF"}
                                variant="outline"
                                color={"#032E59"}
                                fontSize={"12px"}
                                p={"6px 13px"}
                                lineHeight={"13px"}
                                maxH={"25px"}
                                position={"absolute"}
                                right={"0"}
                            >
                                View All
                            </Button>
                        </GridItem>
                    </Grid>
                )}
            </VStack>
        </>
    );
};
