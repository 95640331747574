import { useApiReady, api, fetch, useData, useSave, webappOrigin, formatRequestUrl, API_BACKEND_ENVIRONMENTS, useApiBackend, getUnlockPreviewToken, getEnvironment } from "./api";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { LoginProtectionComponent } from './LoginProtection';
import { navigate } from "@reach/router";

import { AdminProvider, useAdminContext } from "./AdminProvider";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});


export const AppLoginProtected = LoginProtectionComponent;



async function doImpersonate (adminContext, userIdToImpersonate, uri = '') {

    const response = await api.User.DevLoginImpersonate.mutate({
        Id_User: userIdToImpersonate
    });

    if (response.error) {
        alert(JSON.stringify(response, null, 4));
        return;
    } else
    if (response.success?.login) {
        queryClient.invalidateQueries({ queryKey: ["User"] });

        // await adminContext.selectRole(response.success.login.role, false);

        uri = uri || `/${response.success.login.role}/dashboard`;
        await navigate(uri);
        return;
    }
}

async function onImpersonate (adminContext, currentUser, userIdToImpersonate) {

    let previousUsers = JSON.parse(window.localStorage.getItem("re-admin-previous-users") || '[]');
    if (!previousUsers.length || previousUsers[previousUsers.length-1].Id !== currentUser.Id) {
        previousUsers.push({
            Id: currentUser.Id,
            role: currentUser.role,
            uri: `${window.location.pathname}${window.location.search}`
        });
    }
    window.localStorage.setItem("re-admin-previous-users", JSON.stringify(previousUsers));

    await doImpersonate(adminContext, userIdToImpersonate);
}

async function _onImpersonatePrevious (adminContext) {

    let previousUsers = JSON.parse(window.localStorage.getItem("re-admin-previous-users") || '[]');
    if (!previousUsers.length) {
        alert('No previous user to impersonate found!');
    }

    const userToImpersonate = previousUsers.pop();

    if (previousUsers.length) {
        window.localStorage.setItem("re-admin-previous-users", JSON.stringify(previousUsers));
    } else {
        window.localStorage.removeItem("re-admin-previous-users");
    }

    await doImpersonate(adminContext, userToImpersonate.Id, userToImpersonate.uri);
}

export function useApp () {

    const adminContext = useAdminContext();
    const userData = useData('User');

    async function onImpersonatePrevious () {
        return  _onImpersonatePrevious(adminContext);
    }
    let previousUsers = JSON.parse(window.localStorage.getItem("re-admin-previous-users") || '[]');
    if (previousUsers.length) {
        onImpersonatePrevious.Id = previousUsers[previousUsers.length-1].Id;
        onImpersonatePrevious.role = previousUsers[previousUsers.length-1].role;
    } else {
        delete onImpersonatePrevious.Id;
        delete onImpersonatePrevious.role;
    }

    return {
        fetch,
        api,
        useData,
        useSave,
        webappOrigin,
        formatRequestUrl,
        API_BACKEND_ENVIRONMENTS,
        getEnvironment,
        useApiBackend,
        getUnlockPreviewToken,
        isAdminToolsEnabled,
        useAdminContext,
        isUserRole: (role) => (userData.data?.login?.role === role),
        isAdmin: (userData.data?.login?.role === 'admin'),
        onImpersonate: (userIdToImpersonate) => onImpersonate(adminContext, {
            Id: userData.data.user.Id,
            role: userData.data.login.role
        }, userIdToImpersonate),
        onImpersonatePrevious,
    };
}

export async function onLogout () {

    await api.User.Logout.mutate();
    queryClient.invalidateQueries({ queryKey: ["User"] });

    navigate("/");
}

export function isAdminToolsEnabled () {
    return (!!getUnlockPreviewToken());
}

export function App ( {
    LoadingPage,
    ErrorPage,
    children
} ) {

    const ready = useApiReady();

    if (ready === false) {
        return <LoadingPage />;
    } else if (ready?.error) {
        return <ErrorPage
            errorCode={ready.error.code}
            message={ready.error.message}
            goBackUrl={false}
        />;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AdminProvider>
                {children}
            </AdminProvider>
        </QueryClientProvider>
    );
}
