import React, { FunctionComponent } from "react";
import { Badge, Button, HStack, Text } from "@chakra-ui/react";
import { handleColorPercentage } from "./TeacherClassReport";
type Props = {
    cognitiveAreas: {
        id?: number;
        Id?: number;
        label: string;
        percentage?: string;
    }[];
    onCognitiveAreaClick: (cognitiveAreaId: number) => void;
    shouldShowViewAll?: boolean;
    onViewAllClick?: () => void;
    isViewAllActive?: boolean;
    isCognitiveAreaActive: (cognitiveAreaId: number) => boolean;
};

export const StrategySelector: FunctionComponent<Props> = ({
    cognitiveAreas,
    shouldShowViewAll = false,
    onViewAllClick,
    onCognitiveAreaClick,
    isViewAllActive,
    isCognitiveAreaActive,
}) => {
    return (
        <HStack maxW={"100%"} flexWrap={"wrap"} rowGap={"10px"}>
            {cognitiveAreas.map((cognitiveArea, i) => {
                const cognitiveAreaId = cognitiveArea.Id ?? cognitiveArea.id;
                const isActive = isCognitiveAreaActive(cognitiveAreaId);
                return (
                    <Button
                        px={"9px"}
                        bg={"#fff"}
                        color={"#032E59"}
                        key={`${cognitiveArea.label}-${i}`}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        gap={"10px"}
                        borderRadius={"40px"}
                        fontSize={"12px"}
                        border={isActive ? "1px solid #136AFF" : "none"}
                        onClick={() => {
                            onCognitiveAreaClick(cognitiveAreaId);
                        }}
                    >
                        <Text fontWeight={isActive ? "700" : "600"}>
                            {cognitiveArea.label}
                        </Text>
                        <Badge
                            borderRadius={"50%"}
                            color={"#032E59"}
                            w={"15px"}
                            h={"15px"}
                            bg={
                                cognitiveArea.percentage
                                    ? handleColorPercentage(
                                          +cognitiveArea.percentage,
                                          false,
                                      )
                                    : "#F5F5F5"
                            }
                        />
                    </Button>
                );
            })}

            {shouldShowViewAll && (
                <Button
                    px={"9px"}
                    bg={"#fff"}
                    color={"#032E59"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    borderRadius={"40px"}
                    fontSize={"12px"}
                    border={isViewAllActive ? "1px solid #136AFF" : "none"}
                    onClick={onViewAllClick}
                    minW={"87px"}
                >
                    <Text
                        w={"100%"}
                        fontWeight={isViewAllActive ? "700" : "600"}
                    >
                        View All
                    </Text>
                </Button>
            )}
        </HStack>
    );
};
