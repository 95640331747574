import React, { FunctionComponent, useEffect } from "react";
import { CognitiveArea } from "../../components/common/CognitiveArea";
import {
    RouteComponentProps,
    useParams,
    navigate,
    useLocation,
} from "@reach/router";
import {
    GridItem,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
    Text,
    Button,
    Box,
    Badge,
} from "@chakra-ui/react";
import { Loading } from "../../components/common/Loading";
import { Searchbar } from "../../components/common/Searchbar";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useApp } from "../../../lib/app/App";
import { scrollToBookmark } from "../../../lib/util";
import { StrategyReportElement } from "../../components/StrategyReportElement";
import { RellieSelect } from "../../components/common/RellieSelect";
import { ShareReportWithParentModal } from "../../components/teacher/ShareReportWithParentModal";
import { useQueryClient } from "@tanstack/react-query";
import { StrategySelector } from "./StrategySelector";

export const TeacherStudentReportPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const queryClient = useQueryClient();

    const { useData, api, webappOrigin, formatRequestUrl } = useApp();
    const params = useParams();
    const location = useLocation();

    const classesData = useData("Teacher.Classes");

    const selectedClass = params.administratorTeacherLicenseId || "";
    const selectedAssessment = params.assessmentId || "";

    let selectedCognitiveAreaId = null;
    if (location.hash) {
        const m = location.hash.match(/^#strategy-(\d+)$/);
        if (m) {
            selectedCognitiveAreaId = parseInt(m[1]);
        }
    }

    function onBackToOverview() {
        navigate(`/teacher/reports/${selectedClass}`);
    }

    let selectedClassData = {
        query: {
            Id_Administrator_Teacher_License: -1,
        },
    };
    if (classesData.data && selectedClass) {
        classesData.data.forEach((classData) => {
            if (classData.id == selectedClass) {
                selectedClassData = classData;
                selectedClassData.query = {
                    Id_Administrator_Teacher_License: selectedClassData.id,
                };
            }
        });
    }

    const classFullData = useData(
        "Teacher.Report.Class",
        selectedClassData.query,
    );

    // console.log("classesData", classesData.data);
    // console.log("classFullData", classFullData.data);

    let selectedStudentData = null;
    if (classFullData.data) {
        // Exclude students with incomplete assessments and
        // parent registrations that have chosen not to share their report with the teacher.
        classFullData.data.students = classFullData.data.students.filter(
            (student) => {
                if (!student.Id_Assessment) {
                    return false;
                }
                if (
                    student.RegistrationType === "PARENT" &&
                    !student.ShareWithTeacher
                ) {
                    return false;
                }
                return true;
            },
        );

        classFullData.data.students?.forEach((student, i) => {
            if (student.Id_Assessment == selectedAssessment) {
                selectedStudentData = student;

                if (i > 0) {
                    selectedStudentData._hasPrevious = true;
                    selectedStudentData._previousAssessmentId =
                        classFullData.data.students[i - 1].Id_Assessment;
                }
                if (i < classFullData.data.students.length - 1) {
                    selectedStudentData._hasNext = true;
                    selectedStudentData._nextAssessmentId =
                        classFullData.data.students[i + 1].Id_Assessment;
                }
            }
        });
    }

    // console.log("selectedStudentData", selectedStudentData);

    function onPreviousStudent() {
        navigate(
            `/teacher/reports/${selectedClass}/${
                selectedStudentData._previousAssessmentId
            }${params.studentReportType ? `/${params.studentReportType}` : ""}`,
        );
    }

    function onNextStudent() {
        navigate(
            `/teacher/reports/${selectedClass}/${
                selectedStudentData._nextAssessmentId
            }${params.studentReportType ? `/${params.studentReportType}` : ""}`,
        );
    }

    const { isOpen, onClose } = useDisclosure();

    // console.log("selectedStudentData", selectedStudentData);

    const reportData = useData("Teacher.Report.Student", {
        Id_Assessment: selectedStudentData?.Id_Assessment || -1,
    });

    if (
        !selectedCognitiveAreaId &&
        reportData.data?.report &&
        reportData.data?.report.length
    ) {
        selectedCognitiveAreaId = reportData.data.report[0].id;
    }

    const currentStrategy = reportData.data?.report?.find(
        (cognitiveArea: string) => {
            return cognitiveArea.id === selectedCognitiveAreaId;
        },
    );

    const changeHash = (cognitiveAreaId: number) => {
        navigate(
            `${location.pathname}${
                location.search
            }${`#strategy-${cognitiveAreaId}`}`,
        );
    };

    // useEffect(() => {
    //     if (window.location.hash) {
    //         scrollToBookmark();
    //     } else {
    //         window.scrollTo(0, 0);
    //     }
    // }, [
    //     selectedClass,
    //     selectedAssessment,
    //     classesData.data && classFullData.data && reportData.data,
    // ]);

    if (!classesData.data || !classFullData.data || !reportData.data || !selectedStudentData) {
        return <Loading />;
    }

    if (reportData.data.error?.code === "REPORT_NOT_SHARED_BY_PARENT") {
        // NOTE: This should never show as user cannot navigate to it but we have it here for completeness.
        return <Box>Report not shared by parent!</Box>;
    }

    // console.log("reportData", reportData.data);

    const environmentOptions = {
        "IN_CLASS": "I the classroom with teacher and classmates",
        "TEACHER_ONE_ON_ONE": `At school in a one-on-one environment with: teacher \n/ after school program / resource teacher`,
        "AT_HOME": "At home in a quiet setting with parents supporting from the next room",
        "HOMESCHOOLED": "A homeschooled student",
    }

    function renderContent() {
        if (params.studentReportType === "strategies") {
            return (
                <>
                    <Heading
                        marginTop={"28px"}
                        marginBottom={"22px"}
                        fontSize={"20px"}
                        color={"#032E59"}
                    >
                        Strategies - {selectedStudentData.StudentName}
                    </Heading>
                    <StrategySelector
                        isCognitiveAreaActive={(cognitiveAreaId) => {
                            return cognitiveAreaId === selectedCognitiveAreaId;
                        }}
                        cognitiveAreas={reportData.data.report}
                        onCognitiveAreaClick={changeHash}
                    />
                    <VStack
                        spacing={"13px"}
                        w={"100%"}
                        p={"30px"}
                        maxW={"100%"}
                    >
                        {currentStrategy && (
                            <StrategyReportElement
                                selectedCognitiveAreaId={
                                    selectedCognitiveAreaId
                                }
                                cognitiveId={currentStrategy.id}
                                strategies={currentStrategy.strategies}
                                percentage={currentStrategy.percentage}
                                title={currentStrategy.label}
                                colorScheme={currentStrategy.colorScheme}
                            />
                        )}
                    </VStack>
                </>
            );
        } else {

            return (
                <>
                    <Heading
                        marginBottom={"22px"}
                        fontSize={"20px"}
                        color={"#032E59"}
                    >
                        Full Report
                        {selectedStudentData.RegistrationType === "STUDENT" && (
                            <>
                                {(selectedStudentData.Id_Parent && (
                                    <Box
                                        ml={"20px"}
                                        display="inline-block"
                                        fontSize={"12px"}
                                    >
                                        This report is shared with the Parent!
                                    </Box>
                                )) || (
                                    <ShareReportWithParentModal
                                        studentName={
                                            selectedStudentData.StudentName
                                        }
                                        ParentEmail={
                                            selectedStudentData.ParentEmail
                                        }
                                        //TODO ADD PARENT FIRST AND LAST NAME
                                        ParentFirstName={
                                            selectedStudentData.ParentFirstName
                                        }
                                        ParentLastName={
                                            selectedStudentData.ParentLastName
                                        }
                                        onSubmit={async (data) => {
                                            await api.Teacher.Assessment.ShareWithParent.mutate(
                                                {
                                                    Id_Assessment:
                                                        selectedStudentData.Id_Assessment,
                                                    ParentEmail:
                                                        data.ParentEmail,
                                                    ParentFirstName:
                                                        data.ParentFirstName,
                                                    ParentLastName:
                                                        data.ParentLastName,
                                                    webappOrigin,
                                                    registrationBaseUrl:
                                                        formatRequestUrl(
                                                            "api",
                                                            "/api/user/register/from-email",
                                                        ),
                                                },
                                            );

                                            queryClient.invalidateQueries({
                                                queryKey: [
                                                    "Teacher.Report.Student",
                                                ],
                                            });
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </Heading>
                    <VStack
                        spacing={"13px"}
                        bg={"rgba(19, 106, 255, 0.03)"}
                        borderRadius={"20px"}
                        padding={"20px 15px"}
                    >

                        { environmentOptions[reportData.data?.assessment?.Environment] && (
                            <Box
                                ml={"20px"}
                                display="inline-block"
                                fontSize={"12px"}
                            >
                                Questionnaire Environment: {environmentOptions[reportData.data.assessment.Environment]}
                            </Box>
                        )}

                        <VStack
                            borderRadius={"20px"}
                            border={"1px solid #E1ECFF"}
                            bg={"#fff"}
                            w={"100%"}
                            padding={"19px 17px 13px 17px"}
                        >
                            <Heading
                                size="md"
                                color={"#032E59"}
                                display={"flex"}
                                alignItems={"center"}
                                w={"100%"}
                                fontSize={"16px"}
                            >
                                Interests
                            </Heading>

                            <HStack
                                spacing={"10px"}
                                alignItems={"flex-start"}
                                alignSelf={"flex-start"}
                            >
                                {reportData.data.interests.map(
                                    (interest, i) => (
                                        <Badge
                                            key={i}
                                            borderRadius={"40px"}
                                            variant="outline"
                                            colorScheme="brand.blue300"
                                            p={"2px 16px"}
                                            color={"black"}
                                            lineHeight={"20px"}
                                        >
                                            {interest.AnswerText}
                                        </Badge>
                                    ),
                                )}
                            </HStack>
                        </VStack>

                        {reportData.data.report.map((cognitiveArea) => {
                            return (
                                <CognitiveArea
                                    key={cognitiveArea.id}
                                    cognitiveArea={cognitiveArea}
                                    description={
                                        cognitiveArea.firstStrtegy
                                            ?.description || ""
                                    }
                                    skills={cognitiveArea.skills}
                                    colorScheme={cognitiveArea.colorScheme}
                                    onStrategyViewMore={(cognitiveAreaId) => {
                                        navigate(
                                            `/teacher/reports/${selectedClass}/${selectedStudentData.Id_Assessment}/strategies#strategy-${cognitiveAreaId}`,
                                        );
                                    }}
                                />
                            );
                        })}

                        {/* {data && data.map((aptitude, index) => {
                            return (
                                <CognitiveArea
                                    key={`${aptitude.aptitudeName}---${index}`}
                                    aptitudeName={aptitude.aptitudeName}
                                    description={aptitude.description}
                                    skills={aptitude.skills}
                                    strategiesName={aptitude.strategiesName}
                                    colorScheme={aptitude.colorScheme}
                                    isForTeacher={true}
                                    handleMoreParentStrategies={onOpen}
                                />
                            );
                        })} */}
                    </VStack>
                </>
            );
        }
    }

    return (
        <>
            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent borderRadius={"15px"} p={5} minW={"759px"}>
                        <ModalHeader color={"#032E59"}>
                            <Heading size={"sm"}>Parent Strategies</Heading>
                            <Text
                                fontSize={"20px"}
                                fontWeight={"400"}
                                lineHeight={"19px"}
                            >
                                [Category]
                            </Text>
                            <ModalCloseButton />
                        </ModalHeader>
                        <ModalBody
                            display={"grid"}
                            gridTemplateColumns={"319px 319px"}
                            gap={"40px"}
                        >
                            <GridItem
                                display={"flex"}
                                flexDirection={"column"}
                                gap={"5px"}
                            >
                                <Heading color={"#032E59"} size={"sm"}>
                                    Strategy #1
                                </Heading>
                                <Text>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut eniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo
                                    consequat.
                                </Text>
                            </GridItem>
                            <GridItem
                                display={"flex"}
                                flexDirection={"column"}
                                gap={"5px"}
                            >
                                <Heading color={"#032E59"} size={"sm"}>
                                    Strategy #2
                                </Heading>
                                <Text>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut eniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo
                                    consequat.
                                </Text>
                            </GridItem>
                            <GridItem
                                display={"flex"}
                                flexDirection={"column"}
                                gap={"5px"}
                            >
                                <Heading color={"#032E59"} size={"sm"}>
                                    Strategy #3
                                </Heading>
                                <Text>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut eniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo
                                    consequat.
                                </Text>
                            </GridItem>
                            <GridItem
                                display={"flex"}
                                flexDirection={"column"}
                                gap={"5px"}
                            >
                                <Heading color={"#032E59"} size={"sm"}>
                                    Strategy #4
                                </Heading>
                                <Text>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut eniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo
                                    consequat.
                                </Text>
                            </GridItem>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
            <VStack alignItems={"start"} spacing={"35px"}>
                <Searchbar />
                <HStack justifyContent={"space-between"} w={"100%"}>
                    <RellieSelect
                        options={classFullData.data.students.map((student) => {
                            return {
                                label: student.StudentName,
                                value: `${student.Id_Assessment}`,
                            };
                        })}
                        onChangeHandler={(value) => {
                            navigate(
                                `/teacher/reports/${selectedClass}/${value}${
                                    params.studentReportType
                                        ? `/${params.studentReportType}`
                                        : ""
                                }`,
                            );
                        }}
                        placeholder={"SELECT A STUDENT"}
                        value={`${selectedStudentData.Id_Assessment}`}
                    />
                    <HStack>
                        <Button
                            fontSize={"10px"}
                            color={"#032E59"}
                            borderRadius={"60px"}
                            height={"22px"}
                            leftIcon={<IoIosArrowRoundBack />}
                            onClick={onBackToOverview}
                        >
                            Back to Overview
                        </Button>
                        {selectedStudentData._hasPrevious && (
                            <Button
                                fontSize={"10px"}
                                color={"#032E59"}
                                borderRadius={"60px"}
                                height={"22px"}
                                onClick={onPreviousStudent}
                            >
                                Previous Student
                            </Button>
                        )}
                        {selectedStudentData._hasNext && (
                            <Button
                                fontSize={"10px"}
                                color={"white"}
                                bg={"#032E59"}
                                borderRadius={"60px"}
                                height={"22px"}
                                onClick={onNextStudent}
                            >
                                Next Student
                            </Button>
                        )}
                    </HStack>
                </HStack>
                {renderContent()}
            </VStack>
        </>
    );
};
